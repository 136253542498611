#details {
    padding-bottom: 0;

    h3.open-huis {
        color: $color2;
        margin: 20px 0 0 0;
    }

    .wrapper {
        padding: 15px 0 !important;

        &.wrapper-gray {
            padding: 30px 0 !important;
            margin: 15px 0;
        }
    }

    #details-slider,
    #details-thumbs {
        margin-top: 15px;

        .slide {
            img {
                display: block;
                width: 100%;
            }
        }
    }

    #details-thumbs {
        .slide {
            margin-bottom: 20px;
        }
    }

    ul.detail_buttons {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: center;
        margin-bottom: 15px;

        li {
            display: inline-block;
            margin: 15px 3px 0 3px;

            a {
                display: block;
                background: #FFF;
                border: 1px solid #CCC;
                color: #5b5b5b;
                padding: 8px 15px;
                font-family: $font1;
                font-weight: 700;
                font-size: 18px;
                border-radius: 3px;
                text-decoration: none;
                white-space: nowrap;
                text-align: center;

                &:hover {
                    color: $color2;
                }
            }

            &.active a {
                background: $color2;
                color: #FFF;
                border: none;
            }

            &:first-child {
                margin-left: 0;

                a {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .detail_content {
        h2 {
            text-align: center;
            color: $color1;
            font-size: 28px;
            margin-bottom: 30px;
        }

        h3 {
            font-family: $font2;
            font-weight: bold;
            color: #000;
            font-size: 16px;
            margin: 0;
            line-height: 40px;
        }

        .foto {
            a {
                position: relative;
                display: block;

                img {
                    display: block;
                    width: 100%;
                    margin: 15px 0;
                }

                &:hover {
                    &:after {
                        font-family: 'icomoon' !important;
                        content: "\e912";
                        display: block;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        margin: -50px 0 0 -50px;
                        width: 100px;
                        height: 100px;
                        line-height: 100px;
                        text-align: center;
                        font-size: 38px;
                        color: rgba(255,255,255,0.8);
                    }
                }
            }
        }

        dl {
            width: 100%;
            margin: 0 0 40px 0;
            border-top: 1px solid #CCC;

            dd, dt {
                display: inline-block;
                border-bottom: 1px solid #CCC;
                width: 50%;
                line-height: 40px;
                margin: 0px;
            }
        }

        #gmap_streetview,
        #gmap_locatie,
        .gmap_wrapper iframe {
            width: 100%;
            height: 400px;
            border: unset;
        }

        a.meer {
            color: $color2;
            font-weight: bold;
            text-decoration: none;
        }

        .description_short + .description_long {
            display: none;
        }

        textarea {
            height: 96px;
        }

        .object-overig {
            position: relative;
            width: 100%;
            padding-bottom: 56%;
            margin-bottom: 30px;

            div,
            video,
            iframe {
                position: absolute;
                top: 0; left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.totop {
    display: block;
    border: 1px solid #CCC;
    position: fixed;
    right: 15px;
    bottom: 15px;
    border-radius: 3px;
    padding: 15px 15px 10px 15px;
    transform: rotate(180deg);
    text-decoration: none;
    background: #FFF;
    color: #5b5b5b;

    &:hover {
        text-decoration: none;
        color: $color2;
    }
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {
    #details {
        .detail_content {
            h2 {
                font-size: 32px;
            }
        }
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
    #details {

        ul.detail_buttons {
            li:first-child {
                position: absolute;
                left: 0px;
                top: 0px;
            }
        }
    }
}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
    #details {
        .detail_content {
            h2 {
                font-size: 42px;
            }
        }
    }
}
.btn {
    display: inline-block;
    background: $color2;
    color: #FFF;
    padding: 8px 15px;
    font-family: $font1;
    font-weight: 700;
    font-size: 18px;
    border-radius: 3px;
    text-decoration: none;
    white-space: nowrap;
    border: none;
    
    &:hover {
        background: shade($color2, 10%);
    }
    
    &.btn-line {
        background: none;
        border: 3px solid #FFF;
    }
}


// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {
    .btn {
        padding: 10px 15px;
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
    
    #search {
        .form-group {
            width: 14.28%;
            margin-bottom: 0px;
            
            .form-control {
                padding: 10px 15px;
                height: 42px;
            }
        }
    }
}
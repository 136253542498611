#nieuws {
    .nieuws_wrapper {
        a {
            display: block;

            img {
                width: 100%;
                border-radius: 3px;
                margin-bottom: 15px;
            }

            h2 {
                margin-bottom: 5px;
            }

            time {
                display: inline-block;
                margin-bottom: 15px;
            }

            p {
                color: #000;
                font-size: 16px;
            }

            &:hover {
                p {
                    color: $color2;
                }
            }
        }
    }
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {
    #nieuws {
        .nieuws_wrapper {
            a {
                img {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
}
* {
    box-sizing: border-box;
}

body {
    font-family: $font2;
    font-size: 16px;
    line-height: 1.4;

    .container-fluid {
        margin: 0  auto;
        min-width: 320px;
        max-width: 1440px;
    }
}

h1, h2, h3 {
    font-family: $font1;
    font-size: 28px;
    font-weight: 700;
    margin: 0 0 15px 0;
    color: $color2;
    line-height: 1.2;
}

h2 {
    color: $color2;
    margin: 0 0 10px 0;
    font-size: 22px;
}

h3 {
    color: $color1;
    margin: 0 0 10px 0;
    font-size: 18px;
}

a {
    color: $color2;
    text-decoration: none;
    
    &:hover {
        text-decoration: underline;
    }
}

.wrapper ,
.search-wrapper,
.header-wrapper {
    padding: 30px 0;

    &.wrapper-gray {
        background: #F5F5F5;
    }

    &.wrapper-color1 {
        background: $color1;
        color: #FFF;
    }

    &.wrapper-color2 {
        background: $color2;
        color: #FFF;
    }
}

.header-wrapper {
    h1, h2 {
        color: #5b5b5b;
        margin: 0px;

        span {
            font-weight: 400;
        }
    }

    h2 {
        margin-top: 15px;
        color: $color1;
        font-size: 28px;
    }
}

.search-wrapper {
    padding: 0;
}

p {
    margin: 0 0 30px 0;
}

figure {
    margin: 0;
}

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.text-left,
.text-xs-left {
    text-align: left !important;
}

.text-right,
.text-xs-right {
    text-align: right !important;
}

.text-center,
.text-xs-center {
    text-align: center !important;
}

.no-margin-bottom {
    margin-bottom: 0px !important;
}

.no-padding-bottom {
    padding-bottom: 0px !important;
}

.with-padding-bottom {
    padding-bottom: 30px;
}

.image_wrapper {
    background: url('/img/homepage-image.jpg') center center no-repeat;
    background-size: cover;
    padding-bottom: 50%;
}

blockquote {
    background: #F5F5F5;
    padding: 30px;
    margin: 0 0 30px 0;
}

.image_wrapper {
    padding-bottom: 20%;
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {

    body {
        margin-top: 120px;
    }
    
    h1, h2, h3 {
        margin: 0 0 30px 0;
        font-size: 32px;
    }

    h2 {
        margin: 0 0 20px 0;
        font-size: 24px;
    }

    h3 {
        margin: 0;
        font-size: 20px;
    }

    .header-wrapper {
        h2 {
            font-size: 32px;
        }
    }

    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {

    .wrapper {
        padding: 45px 0;
    }

    .header-wrapper {
        h2 {
            margin-top: 0px;
        }
    }

    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }

    .with-padding-bottom {
        padding-bottom: 45px;
    }

    .image_wrapper:not(.single) {
        padding-bottom: 33.125%;
    }

}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {

    body {
        margin-top: 160px;
    }
    
    h1, h2, h3 {
        font-size: 42px;
    }

    h2 {
        font-size: 30px;
    }

    h3 {
        font-size: 24px;
    }

    .header-wrapper {
        h2 {
            font-size: 42px;
        }
    }

    .wrapper {
        padding: 60px 0;
    }

    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-right {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }

    .with-padding-bottom {
        padding-bottom: 60px;
    }
}